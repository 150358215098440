import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "path": "/blog/when-to-call-it-quits-on-a-project",
  "title": "When to Call it Quits on a Project: Stepping Back and Taking a Break",
  "excerpt": "Abandoning a project is not a bad thing, sometimes it is necessary",
  "tags": ["web development", "learning", "frontend"],
  "coverPhoto": {
    "cloudinaryAssetData": true,
    "cloudName": "dmocgebcq",
    "publicId": "portfolio/blog-covers/david-preston-mW2NETqR49A-unsplash_cpqss4.jpg",
    "originalHeight": 2500,
    "originalWidth": 3500,
    "defaultBase64": "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mMMXG/8HwAEwAI0Bj1bnwAAAABJRU5ErkJggg==",
    "defaultTracedSVG": "data:image/svg+xml,%3Csvg%20height%3D%229999%22%20viewBox%3D%220%200%209999%209999%22%20width%3D%229999%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22m0%200h9999v9999h-9999z%22%20fill%3D%22%23f9fafb%22%2F%3E%3C%2Fsvg%3E"
  },
  "draft": false,
  "type": "blog",
  "date": "2018-03-29T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I was trying to make a Pomodoro timer for the `}<a parentName="p" {...{
        "href": "https://scotch.io/tutorials/code-challenge-6-build-a-pomodoro-timer"
      }}>{`Scotch.io Challenge #6`}</a>{` using a framework I had just started learning, Angular. It was going pretty well at first. I had replicated the desired design look. I'd setup the components and just needed to create the javascript to make the timer actually countdown from a given time. This is where it took a sharp downturn.`}</p>
    <h2>{`Problem Strikes`}</h2>
    <p>{`I just couldn't get the timer to countdown no matter what I tried, I felt I had a good game plan. I thought I knew the steps to make this work and it would be no problem. It did not work out that way at all. I spent a few hours trying to lookup any examples of people who'd done something similar, to no avail. None of the examples where using the setup I had of using Angular CLI. They were all using a setup that relied on \\$scope and I didn't know anything about that or how it related to my setup.`}</p>
    <p>{`I'm a pretty determined person so I spent a good part of my night trying to break this "problem hump". Thinking if I just pushed enough everything would click into place and it would all make sense. That's usually how it works for me, I do research and trial & error and then things works. I kind of figure out what I needed to do and then it's something I've learned.`}</p>
    <h2>{`Calling it Quits`}</h2>
    <p>{`It was getting close to 3am. I was super tired and very angry, at myself. I wanted so badly to do this, I thought that I'm better than this I can make it work. That wasn't actually fair or true. It had nothing to do with my abilities in the sense of I'm a failure but more that I'm really new at this. Why should I be expected to know everything about a framework I just started using not even a month ago. Once I realized that I thought it was silly to push myself to learn everything about Angular in the wee hours of the morning.`}</p>
    <p>{`I didn't want to but I put this project, making a Pomodoro Timer with Angular, on the backburner. I made it in `}<a parentName="p" {...{
        "href": "https://codepen.io/Vpugh/pen/GxmmwO"
      }}>{`Vue`}</a>{` and don't regret that. That's a framework I've had a few more months experience with and I feel really comfortable in. Took about two days but I got it working and fleshed it out into a full functional Pomodoro Timer.`}</p>
    <h2>{`End Result`}</h2>
    <p>{`I would have never gotten that far if I was too upset about my Angular project and called it quits. I know when I have more experience in using Angular and have a better handle of how it works I will come back and finish that project. There is not doubt in my mind.`}</p>
    <p>{`Sometimes you need to give up and come back later. If you don't know why it's not working or you feel your missing something, step back and get a different perspective. There is no shame in not having enough experience. Just don't give up and you can always come back later.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      